<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-currency-usd</v-icon>
        Eduzz
      </v-card-title>
      <v-divider></v-divider>
      <v-tabs class="" v-model="tab">
        <v-tab class="caption font-weight-bold"> Sales </v-tab>
        <v-tab class="caption font-weight-bold"> Duplicados </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-text>
            <Sales v-if="tab === 0" />
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <SalesDuplicadas v-if="tab === 1" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Eduzz",

  data() {
    return {
      tab: 0,
    };
  },

  components: {
    Sales: () => import("./components/Sales.vue"),
    SalesDuplicadas: () => import("./components/SalesDuplicadas.vue"),
  },
};
</script>

<style></style>
